import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { navigate } from 'gatsby-link';
import useGlobal from '../store';
import Container from '../components/styles/Container';
import { padding } from '../theme';
import { GarageHeading } from '../components/styles/GaragePage';
import Page from '../components/Page';
import { loginRedirectEffect } from '../utils/helpers';
import List from '../components/garage/List';

const SwitchDriver = ({ location }) => {
  const [state, actions] = useGlobal();
  const { drivers, driver, token, loadingToken } = state;
  const { switchDriver } = actions;

  useEffect(
    () => {
      if (driver && driver.id) navigate(`/garage${location.search}`);
    },
    [driver]
  );

  loginRedirectEffect(token, loadingToken, location);

  return (
    <Page>
      <Container py={padding}>
        <GarageHeading>Switch Driver</GarageHeading>
        <List
          clickHandler={e => switchDriver(parseInt(e.currentTarget.value, 10))}
          elements={drivers}
        />
      </Container>
    </Page>
  );
};

SwitchDriver.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
};

export default SwitchDriver;
